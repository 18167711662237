/*
Colors

Flype colors

Colors:
Primary: #493AEB - Primary color
Primary-light: #E6E9FF - Primary color light variance
On-primary: #FFFFFF - Color used on Primary
Secondary: #2EB4FF - Secondary color
Dark-gray: #707070 - Dark gray text

Markup:
<div class="bg-gradient-blue" style="height: 80px;">Graident blue mostly used as background color</div>


Styleguide Foundation.Colors
*/
:root {
    --color-primary: #493AEB;
    --color-on-primary: #FFFFFF;
    --color-primary-light: #E6E9FF;
    --color-secondary: #2EB4FF;
    --color-gradient: linear-gradient(90deg, #3F32CC, #3F8FFF);
    --text-dark-gray: #707070;
}

// Global defaults
body {
    background-color: #3F32CC;
    color: #707070;
}

.App {
    background: var(--color-gradient);
}

/* Colors */
.bg-gradient-blue {
    background: var(--color-gradient);
    color: white;
}

.bg-white {
    background: white;
    /* color: inherit; */
}