// Breakpoints 
// reference: https://material.io/design/layout/responsive-layout-grid.html#breakpoints
@mixin for-size($size) {
  @if $size==xsmall-only or $size==below-small {
    @media (max-width: 599px) {
      @content;
    }
  }

  @else if $size==small-up {
    @media (min-width: 600px) {
      @content;
    }
  }

  @else if $size==below-medium {
    @media (max-width: 1023px) {
      @content;
    }
  }

  @else if $size==medium-up {
    @media (min-width: 1024px) {
      @content;
    }
  }

  @else if $size==large-up {
    @media (min-width: 1440px) {
      @content;
    }
  }
}