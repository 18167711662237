/*
Grid

Content grid with default responsive side margins

Markup:
<div class="Grid {{modifier_class}} bg-gradient-blue">Content grid</div>

.Grid--small

Styleguide Foundation.Layout.Grid
*/

#root,
.App {
    overscroll-behavior: none;
    flex: 1;
}

html,
body {
    overscroll-behavior: none;
    height: 100%;
}

.no-scroll {
    overflow: hidden;
    @include for-size(medium-up) {
        overflow: initial;
    }
}

#root {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.Grid {
    margin: auto;
    padding: 0 32px;

    @include for-size(medium-up) {
        padding: 0 28px;
    }

    &--small {
        max-width: 812px;
    }

    &--centered {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}

@include for-size(medium-up) {
    .Grid\@medium-up {
        padding: 0 40px;
    }
}

/*
Section

Markup:
<section className="Section bg-gradient-blue Grid">Semantic section</section>

Styleguide Foundation.Layout.Section
*/
.Section {
    margin: auto;
    position: relative;
}

input:focus {
    outline: none;
}
div:focus {
    outline: none;
}
