@import '../foundation/Responsive.scss';

.Chat {
    position: fixed;
    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: column;
    z-index: 90000000000;
    @include for-size(medium-up) {
        // display: none;
        // position: relative;
        bottom: 15px;
        right: 20px;
        width: 350px;
        height: 500px;
    }
}

.Chat-header {
    flex: 1;
    flex-direction: column;
    display: inline-flex;
    @include for-size(medium-up) {
        background: transparent;
    }
}

.Chat-title {
    flex-direction: row;
    justify-content: center;
    align-items: center;
    align-content: center;
    padding-top: 10px;
    padding-bottom: 10px;
    @include for-size(medium-up) {
        visibility: hidden;
        display: none;
    }
}

.Chat-box {
    display: flex;
    flex: 1;
    flex-direction: column;
    background-color: #fff;
    border-top-left-radius: 38px;
    border-top-right-radius: 38px;
    padding: 30px;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    @include for-size(medium-up) {
        // border-bottom-left-radius: 38px;
        // border-bottom-right-radius: 38px;
        box-shadow: 0px 0px 15px #909090;
    }
    .messages {
        flex-grow: 1;
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;
    }
}

.close-wrapper {
    background: rgb(73, 58, 235);
    align-self: flex-end;
    position: absolute;
    border-radius: 50%;
    top: 8px;
    right: 10px;
    display: none;
    cursor: pointer;
    @include for-size(medium-up) {
        display: block;
    }
}
