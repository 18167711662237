@import '../../foundation/Responsive.scss';

.userPicture {
    width: 72px;
    height: 72px;
    border-radius: 36px;
    overflow: hidden;

    @include for-size(medium-up) {
        width: 111px;
        height: 111px;
        border-radius: 61px;
        overflow: hidden;
    }
}

.userPicture>img {
    height: 100%;
    width: 100%;
    object-fit: cover;
}
