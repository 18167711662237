@import-normalize;

// Foundation
//
// Weight: 0
//
// Styleguide Foundation
@import 'foundation/Color.scss';
@import 'foundation/Responsive.scss';
@import 'foundation/Typography.scss';

// Layout
//
// Styleguide Foundation.Layout
@import 'foundation/Layout.scss';

// Spacing
//
// Generic reusable spacing classes, add more sizes if it fits
//
// Styleguide Foundation.Spacing
@import 'foundation/Spacing.scss';

// App-wide stuff
.App {
    display: flex;
    flex-direction: column;
}

.App-logo {
    height: 22px;
    padding: 24px;
    pointer-events: none;

    @include for-size(medium-up) {
        height: 32px;
        padding: 26px;
    }
}

.App-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: var(--color-on-primary);
    // padding-top: 36px; // extra
    padding-top: env(safe-area-inset-top);
    flex-shrink: 0;
}

.App-link {
    color: #61dafb;
}

// Components
.Feed-card {
    max-width: 680px;
    border-radius: 22px;

    background-color: white;
    color: black;

    margin: auto;
    padding: 20px 16px 16px 24px;

    display: flex;
    flex-wrap: wrap;

    @include for-size(medium-up) {
        padding: 20px 20px 20px 35px;
    }

    &__header {
        flex: 0 1 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 14px;

        @include for-size(medium-up) {
            font-size:18px;
        }
    }

    &__main {
        flex: 0 1 100%;
        align-content: 'center';
        justify-content: 'center';
        justify-items: 'center';
        font-size: 15px;
        margin-top: 8px;

        @include for-size(medium-up) {
            font-size: 19px;
            margin-top: 16px;
        }
    }

    & + & {
        margin-top: 24px;
    }
}

.Hero-card {
    max-width: 1120px;
    border-radius: 38px;
    margin: auto;
    text-align: center;

    display: flex;
    flex-direction: column;

    background-color: white;
    color: var(--text-dark-gray);

    @include for-size(below-medium) {
        min-height: calc(100vh - 196px);
        margin-bottom: -38px;
        padding-bottom: 38px;
    }

    &__header {
        flex: 0 1 auto;
        display: flex;
        justify-content: space-between;
        // margin: 0 -8px;

        margin-top: 24px;
    }

    &__main {
        flex: 1 1 auto;

        display: flex;
        flex-direction: column;
        align-items: center;

        margin-top: 32px;

        @include for-size(medium-up) {
            margin-top: -36px;
        }
    }

    &__main_no_header {
        flex: 1 1 auto;

        display: flex;
        flex-direction: column;
        align-items: center;

        margin-top: 32px;
    }

    &__footer {
        z-index: 10;
        margin-bottom: -22px;
        margin-top: 32px;
        text-align: center;
    }
}

.Parcel {
    display: flex;

    &__thumbnail {
    }

    &__info {
        display: flex;
        flex-wrap: wrap;
    }

    &__headline {
        flex: 0 1 100%;
    }

    &__subheadline {
        flex: 1 1 auto;
    }

    &__price {
        flex: 0 1 auto;
    }
}

.Traveler {
    display: flex;
    // flex-wrap: wrap;

    &__rating {
        flex: 1 1 auto;
        display: flex;
        align-items: flex-end;

        margin-top: 8px;

        @include for-size(medium-up) {
            margin-top: 24px;
        }
    }
}

.spinner-wrapper {
    display: flex;
    height: 100%;
    width: 100%;
    align-items: center;
    justify-content: center;
}
