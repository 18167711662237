.card_component {
    display: block;
    background: white;
    padding: 2rem;
    border-radius: 38px;
    padding: 58px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 40px 40px;
    position: relative;
}

@media only screen and (max-width: 800px) {
    .card_component {
        margin: 0 0 40px;
    }
}

.content_width {
    margin-top: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;

    .Icon-flype {
        margin-bottom: 50px;
    }

    .building_type {
        display: flex;
        flex-direction: column;
        align-items: center;
        h3 {
            margin-bottom: 20px;
        }

        .building_type__choices {
            display: flex;
            align-items: center;
            margin-bottom: 50px;
            :first-child {
                margin-right: 25px;
            }
        }
        .choices_button {
            padding: 20px;
            border-radius: 8px;
            border: 1px solid #707070;
            background: white;
            min-width: 140px;
            font-size: 17px;
            color: #707070;
        }
        .is-active {
            border: 1px solid #2eb4ff;
            color: #2eb4ff;
        }
    }

    .parcel_outside__wrapper {
        margin-bottom: 30px;
        display: flex;
        flex-direction: column;
        align-items: center;
        h3 {
            margin-bottom: 18px;
        }
    }
    .parcel_outside__options {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 50%;
    }

    .floor_number__wrapper {
        margin-bottom: 30px;
        display: flex;
        flex-direction: column;
        align-items: center;

        h3 {
            margin-bottom: 18px;
        }
    }
    .floor_number__input {
        background: #e6e9ff;
        border-radius: 8px;
        padding: 5px;
        border: none;
        width: 40px;
        height: 40px;
        font-size: 20px;
        color: #707070;
        text-align: center;
    }
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    .notification__wrapper {
        margin-bottom: 30px;
        display: flex;
        flex-direction: column;
        align-items: center;

        h3 {
            margin-bottom: 18px;
        }
    }

    .other_info__wrapper {
        margin-bottom: 60px;
        h3 {
            margin-bottom: 18px;
        }
        textarea {
            resize: none;
            background: #e6e9ff;
            border-radius: 8px;
            padding: 5px;
            border: none;
            height: 100px;
            width: 100%;
            font-size: 20px;
            color: #707070;
        }
    }
}

.card_component__back-button {
    position: absolute;
    top: 30px;
    left: 30px;
}

.skip_button {
    color: #2eb4ff;
    border: none;
    background: none;
    font-size: 20px;
    margin-top: 30px;
    border-bottom: 1.5px solid #2eb4ff;
}

label {
    display: flex;
    align-items: center;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 17px;
    user-select: none;

    input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
    }
    .checkmark,
    .link {
        position: absolute;
        top: 0;
        left: 0;
        height: 20px;
        width: 20px;
        border-radius: 50%;
        border: 2px solid #707070;
    }

    input:checked ~ .checkmark {
        border: 2px solid #2eb4ff;
    }

    .checkmark:after {
        content: '';
        position: absolute;
        display: none;
    }

    .checkmark:after {
        left: 20%;
        top: 20%;
        width: 12px;
        height: 12px;
        background: #2eb4ff;
        border-width: 0 3px 3px 0;
        border-radius: 50%;
    }
}
label input:checked ~ .checkmark:after {
    display: block;
}

.radio > label > input {
    margin-right: 8px;
}

.switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
}

.switch input {
    opacity: 0;
    width: 60px;
    height: 34px;
    position: absolute;
    z-index: 100;
    cursor: pointer;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: 0.4s;
}

.slider:before {
    position: absolute;
    content: '';
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    transition: 0.4s;
}

input:checked + .slider {
    background-color: #2eb4ff;
}

input:focus + .slider {
    box-shadow: 0 0 1px #2eb4ff;
}

input:checked + .slider:before {
    transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 50%;
}

.switch_wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.switch_status {
    margin: 0;
    margin-top: 10px;
    font-size: 1rem;
}

.input_floor_container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.floor_btn {
    background: none;
    border: none;
    font-size: 1.5rem;

    p {
        margin: 0 0.6rem;
    }
}
