/*
Spaced content block

Add vertical space on content blocks with base-8 spacings.
.spaced-x adds a margin-top
.spaced-below-x adds a margin-bottom
Spcings on large screens will be 1.2 times.

Weight: -1

Markup:
<div class="bg-gradient-blue">A content block</div>
<div class="bg-gradient-blue">Another block</div>
<div class="bg-gradient-blue spaced-8">A 8-px spaced block</div>
<div class="bg-gradient-blue spaced-64">A 64-px spaced block</div>
<div class="bg-gradient-blue spaced-4">A 4-px (smallest) spaced block</div>


Styleguide Foundation.Spacing.SpacedBlock
*/

/*
Spacer block

Use a standalone .spacer block when .spaced-x block doesn't work

Weight: 2

Markup:
<div class="bg-gradient-blue">A content block</div>
<div class="spacer-8">(a spacer)</div>
<div class="bg-gradient-blue">Another content block</div>

Styleguide Foundation.Spacing.SpacerBlock
*/

$RESPONSIVE_SCALING_FACTOR: 1.2;

.spaced-4 {
  margin-top: 4px;
}

@for $i from 1 through 8 {
  .spaced-#{$i*8} {
    margin-top: #{$i*8}px;

    @include for-size(medium-up) {
      margin-top: #{$i*8*$RESPONSIVE_SCALING_FACTOR}px;
    }
  }

  .spaced-below-#{$i*8} {
    margin-bottom: #{$i*8}px;

    @include for-size(medium-up) {
      margin-bottom: #{$i*8*$RESPONSIVE_SCALING_FACTOR}px;
    }
  }

  .spacer-#{$i*8} {
    padding-top: #{$i*8}px;

    @include for-size(medium-up) {
      padding-top: #{$i*8*$RESPONSIVE_SCALING_FACTOR}px;
    }
  }
}

/*
Horizontal spacing

Weight: 5

Markup:
<div class="text-body">
<span class="bg-gradient-blue">Left content</span>
<span class="bg-gradient-blue spaced-left-12">Content with spaced-left-12</span>
<span class="bg-gradient-blue spaced-left-16">Another content with spaced-left-16</span>
</div>

Styleguide Foundation.Spacing.HorizontalSpacing
*/
.spaced-left-12 {
  margin-left: 12px;
}

.spaced-left-16 {
  margin-left: 16px;
}
