@import '../../foundation/Responsive.scss';

/* 
Button

Buttons - default is primary, CTA.

Markup:
<button class="Button {{modifier_class}}">Button</button>

:hover - Highlight the button when hovered.
.Button--secondary - Secondary button

Styleguide Components.Button
*/
button {
  --color-shadow: #0C28BF7A;
}

.Button {
  border: none;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-weight: 600;
  font-size: 1rem;
  border-radius: 1.2rem;
  width: 100%;
  max-width: 21.12rem;

  box-shadow: 0 3px 6px var(--color-shadow);

  background-color: var(--color-primary);
  color: var(--color-on-primary);
  padding: 12px;

  &:hover {
    opacity: 0.8;
  }


  @include for-size(medium-up) {

    font-size: 20px;
  }
}

.Button--secondary {
  background-color: white;
  border: 1px solid var(--color-primary);
  color: var(--color-primary);

}

/* 
FAB Button

FAB Buttons - Flaoting action button with Icon

Markup:
<button class="Button Button--fab"><span class="Icon Icon-home"></span></button>

:hover - Highlight the button when hovered.

Styleguide Components.Button.Fab
*/
.Button--fab {
  padding: 16px;
  border-radius: 100%;
  width: auto;


  @include for-size(medium-up) {
    padding: 24px;
  }
}

.OpenReport {
    display: flex;
    flex-direction: row;
    align-items: center;
}