// Global defaults/ resets
body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-weight: normal;

  font-size: 17px;
  line-height: 1.2;

  @include for-size(medium-up) {
    font-size: 22px;
  }
}

h1,
h2,
h3,
h4,
h5 {
  margin: 0;
  font-weight: normal;
}

/*
Typography

Markup:
<h1 class="text-title-1">Title 1</h1>
<h2 class="text-title-2">Title 2</h1>
<h1 class="text-title-2">H1 that looks like Title 2</h1>
<div class="text-headline">Headline</div>
<div class="text-subheader">Subheader</div>
<div class="text-body">Body text</div>
<div class="text-footnote">Footnote</div>


Styleguide Foundation.Typography
*/
.text-title-1 {
  font-size: 28px;
  text-align: center;

  @include for-size(medium-up) {
    font-size: 44px;
  }
}

.text-title-2 {
  font-size: 18px;

  @include for-size(medium-up) {
    font-size: 18px;

    &\@medium-up {
      font-size: 30px;
    }
  }
}


.text-headline {
  font-size: 15px;

  @include for-size(medium-up) {
    font-size: 30px;

    &\@medium-up {
      font-size: 30px;
    }
  }
}

.text-subheadline {
  font-size: 15px;

  @include for-size(medium-up) {
    font-size: 16px;
  }
}

.text-body {
  font-size: 16px;
}

.text-footnote {
  font-size: 11px;
}
