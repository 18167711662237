@import '../../foundation/Responsive.scss';

.parcelImage {
    width: 55px;
    height: 55px;

    @include for-size(medium-up) {
        width: 60px;
        height: 60px;
    }
}
