@import 'Responsive.scss';

/*
Icon

Flype icons: (svg icons are not linked properly yet)

Markup:
<div style="background-color: black;">
  <span class="Icon {{modifier_class}}"></span>
</div>

.Icon-chat - Chat icon
.Icon-flype - Flype icon
.Icon-home - Home icon

Styleguide Foundation.Icon
*/
.Icon {
    display: inline-block;
    vertical-align: middle;
    height: 24px;
    width: 24px;
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: contain;

    @include for-size(medium-up) {
        height: 28px;
        width: 28px;
    }
}

$icon-keys: 'chat', 'flype', 'home', 'close', 'settings', 'star',
    'star-inactive', 'star-filled', 'back-arrow', 'alert', 'send';

@each $key in $icon-keys {
    .Icon-#{$key} {
        background-image: url('/assets/#{$key}.svg');
    }
}

// Icons of non-standard sizes
.Icon-flype {
    width: 40px;
    height: 24px;
    margin-left: -8px;

    @include for-size(medium-up) {
        width: 64px;
        height: 28px;
    }
}

.Icon-flype--large {
    width: 64px;
    height: 38px;
    margin-left: -16px;

    @include for-size(medium-up) {
        width: 132px;
        height: 76px;
        margin-left: -35px;
    }
}

.Icon-alert {
    width: 18px;
    height: 18px;
    @include for-size(medium-up) {
        width: 24px;
        height: 24px;
    }
}

.Icon-sent {
    background: 'red';
}
